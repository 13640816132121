import React, { Component } from "react";

class AboutTwo extends Component {
  render() {
    let title = "Assertive",
      description =
        "We chose the name Assertive Web Solutions because we are extremely confident in our ability to add value and bring solutions to your business with the right software and tools. We have a heavy emphasis on efficiency and simplicity and we want the best for your business.";
    return (
      <React.Fragment>
        <div className='about-wrapper'>
          <div className='container'>
            <div className='row row--35 align-items-center'>
              <div className='col-lg-5 col-md-12'>
                <div className='thumbnail'>
                  <img
                    className='w-100'
                    src='/assets/images/about/about-tech.jpg'
                    alt='About Images'
                  />
                </div>
              </div>

              <div className='col-lg-7 col-md-12'>
                <div className='about-inner inner'>
                  <div className='section-title'>
                    <h2 className='title'>{title}</h2>
                    <p className='description'>{description}</p>
                  </div>
                  <div className='row mt--30 mt_sm--10'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                      <div className='about-us-list'>
                        <h3 className='title'>Who we are</h3>
                        <p>
                          We are a software development company and we
                          specialize in custom web applications and web
                          solutions using modern technologies.
                        </p>
                      </div>
                    </div>
                    {/* <div className='col-lg-6 col-md-12 col-sm-12 col-12'>
                      <div className='about-us-list'>
                        <h3 className='title'>Ideal Clients</h3>
                        <p>
                          There are many vtions of passages of Lorem Ipsum
                          available, but the majority have suffered.
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutTwo;
