import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import RiseLoader from "react-spinners/RiseLoader";

const Result = () => {
  return (
    <p className='success-message'>
      Your Message has been successfully sent. We will contact you soon!
    </p>
  );
};

const ErrorResult = () => {
  return (
    <p className='error-message'>
      Something went wrong, try submitting the form again!
    </p>
  );
};

function ContactForm() {
  const [result, showresult] = useState(false);
  const [resultErr, showresultErr] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const onSubmit = async (values, form) => {
    setSubmitted(true);
    const { fullName, email, message, subject, phone } = values;
    const requestBody = {
      to: email,
      name: fullName,
      phone,
      subject,
      message,
    };
    const body = JSON.stringify(requestBody);
    try {
      const response = await fetch(`${apiUrl}/send-info-response-email`, {
        body,
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });

      if (response.status === 200) {
        form.reset();
        // Clear field-level errors
        Object.keys(values).forEach((fieldName) => {
          form.resetFieldState(fieldName);
        });
        setSubmitted(false);
        showresult(true);
        setTimeout(() => {
          showresult(false);
        }, 5000);
      }
      console.log(response);
    } catch (error) {
      setSubmitted(false);
      showresultErr(true);
      setTimeout(() => {
        showresultErr(false);
      }, 5000);
      console.log(error);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = [];
        function validateEmail(email) {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
        }
        if (!values.email) {
          errors.email = "Email Address is Required";
        } else if (!validateEmail(values.email)) {
          errors.email = "Not a valid email address";
        }
        if (!values.fullName) {
          errors.fullName = "Name is Required";
        }
        if (!values.phone) {
          errors.phone = "Phone Number is Required";
        }
        if (!values.subject) {
          errors.subject = "A Subject is Required";
        }
        if (!values.message) {
          errors.message = "A Message is Required";
        }
        return errors;
      }}
      render={({ handleSubmit, valid }) => (
        <form onSubmit={handleSubmit}>
          <div className='rn-form-group'>
            <Field name='fullName'>
              {({ input, meta }) => (
                <div>
                  <input {...input} type='text' placeholder='Your Name' />
                  {meta.error && meta.touched && (
                    <span className='error-text'>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
          </div>
          <div className='rn-form-group'>
            <Field name='email'>
              {({ input, meta }) => (
                <div>
                  <input {...input} type='email' placeholder='Your Email' />
                  {meta.error && meta.touched && (
                    <span className='error-text'>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
          </div>
          <div className='rn-form-group'>
            <Field name='phone'>
              {({ input, meta }) => (
                <div>
                  <input
                    {...input}
                    type='text'
                    name='phone'
                    placeholder='Phone Number'
                  />
                  {meta.error && meta.touched && (
                    <span className='error-text'>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
          </div>
          <div className='rn-form-group'>
            <Field name='subject'>
              {({ input, meta }) => (
                <div>
                  <input
                    {...input}
                    type='text'
                    name='subject'
                    placeholder='Subject'
                  />
                  {meta.error && meta.touched && (
                    <span className='error-text'>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
          </div>
          <div className='rn-form-group'>
            <Field name='message'>
              {({ input, meta }) => (
                <div>
                  <textarea
                    {...input}
                    placeholder='Your Message'
                    required
                  ></textarea>
                  {meta.error && meta.touched && (
                    <span className='error-text'>{meta.error}</span>
                  )}
                </div>
              )}
            </Field>
          </div>
          <div
            className='rn-form-group'
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <button
              className='rn-button-style--2 btn-solid'
              style={{ marginRight: "40px" }}
              type='submit'
              value='submit'
              name='submit'
              id='mc-embedded-subscribe'
              disabled={!valid}
            >
              Submit Now
            </button>
            {submitted ? (
              <RiseLoader color='#36d7b7' style={{ paddingTop: "20px" }} />
            ) : undefined}
          </div>
          <div className='rn-form-group'>{result ? <Result /> : null}</div>
          <div className='rn-form-group'>
            {resultErr ? <ErrorResult /> : null}
          </div>
        </form>
      )}
    ></Form>
  );
}
export default ContactForm;
