import React, { Component } from "react";
import { FiLayers, FiCloud, FiMonitor, FiDatabase } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiCloud />,
    title: "Web App Development",
    description:
      "Custom web applications to enhance business operations and processes.",
  },
  {
    icon: <FiMonitor />,
    title: "Website Development",
    description:
      "Develop and design websites for businesses to increase online visibility.",
  },
  {
    icon: <FiDatabase />,
    title: "Database Management",
    description:
      "Setup and manage databases, handle migrations, and manage security.",
  },
  {
    icon: <FiLayers />,
    title: "Integrations",
    description: "Setup and manage integrations with third party APIs.",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Services",
      description =
        "Assertive Web Solutions offers a variety of services for you and your business needs. Contact us today if you are interested in learning more about how we can serve you!";
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-lg-4 col-12'>
            <div className='section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10'>
              <h2 className='title'>{title}</h2>
              <p>{description}</p>
              <div className='service-btn'>
                <a className='btn-transparent rn-btn-dark' href='/contact'>
                  <span className='text'>Request a service</span>
                </a>
              </div>
            </div>
          </div>
          <div className='col-lg-8 col-12 mt_md--50'>
            <div className='row service-one-wrapper'>
              {ServiceList.map((val, i) => (
                <div className='col-lg-6 col-md-6 col-sm-6 col-12' key={i}>
                  <a href='/service-details'>
                    <div className='service service__style--2'>
                      <div className='icon'>{val.icon}</div>
                      <div className='content'>
                        <h3 className='title'>{val.title}</h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
