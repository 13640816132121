import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Main skills",
      tab3 = "Experience";
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className='tabs-area'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className='single-tab-content'>
                      <ul>
                        <li>
                          <a href='/service'>
                            Javascript/Typescript & NodeJs{" "}
                            <span> - Development</span>
                          </a>
                          Develop complex web services & applications.
                        </li>
                        <li>
                          <a href='/service'>
                            Systems Architecture
                            <span> - System Design</span>
                          </a>
                          Detailed technical designs, performance ...
                        </li>
                        <li>
                          <a href='/service'>
                            Database Design & API's<span> - Server Side</span>
                          </a>
                          Design & implement database & API's.
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className='single-tab-content'>
                      <ul>
                        <li>
                          <a href='/service'>
                            Full Stack Software Engineer III
                            <span> - Sinclair Broadcast Group</span>
                          </a>{" "}
                          2022 - Current
                        </li>
                        <li>
                          <a href='/service'>
                            Lead Software Engineer<span> - Lab Dynamo</span>
                          </a>{" "}
                          2018 - 2022
                        </li>
                        <li>
                          <a href='/service'>
                            Freelance Web Development
                            <span> - Self Employed</span>
                          </a>{" "}
                          2016 - Current
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
