import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className='brand-style-2'>
          <li>
            <img
              src='/assets/images/brand/cascade-logo.jpg'
              alt='Cascade Motor Sports Logo'
            />
          </li>
          <li>
            <img
              src='/assets/images/brand/lab-dynamo-logo.png'
              alt='Lab Dynamo Logo'
            />
          </li>
          <li>
            <img src='/assets/images/brand/SMC-logo.png' alt='Logo Images' />
          </li>
          <li>
            <img
              src='/assets/images/brand/ecomdash-logo.png'
              alt='Ecomdash Logo'
            />
          </li>
          <li>
            <img
              src='/assets/images/brand/shipstation-logo.png'
              alt='ShipStation Logo'
            />
          </li>
          <li>
            <img src='/assets/images/brand/wps-logo.png' alt='WPS Logo' />
          </li>
          <li>
            <img
              src='/assets/images/brand/docusign-logo.png'
              alt='Docusign Logo'
            />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
