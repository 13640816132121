import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.props.pageTitle} || Software Company</title>
          <meta
            name='description'
            content='Assertive Web Solutions is a software company specialized in creating applications for businesses of all sizes. Assertive Web Solutions offers fast, dynamic, and efficient software solutions to clients. We also handle integrations and improving current systems or softwares.'
          />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
