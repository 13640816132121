import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
  render() {
    return (
      <div className='contact-form--1'>
        <div className='container'>
          <div className='row row--35 align-items-start'>
            <div className='col-lg-6 order-2 order-lg-1'>
              <div className='section-title text-left mb--50'>
                <h2 className='title'>Contact Us.</h2>
                <p className='description'>
                  We would love to hear from you. If you are interested in
                  working with us, go ahead and fill out the contact form and
                  someone from our team will reach out to you.
                </p>
              </div>
              <div className='form-wrapper'>
                <ContactForm />
              </div>
            </div>
            <div
              className='col-lg-6 order-1 order-lg-2'
              style={{ alignSelf: "center" }}
            >
              <div className='thumbnail mb_md--30 mb_sm--30'>
                <img src='/assets/images/about/contact-pic.jpg' alt='trydo' />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
